<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="lg"
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
      @show="fetchDataCombobox"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
            >
              <b-form-group>
                <label for="code">Tên ngành nghề<span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Tên ngành nghề"
                  rules="required"
                  :custom-messages="{required: `Tên ngành nghề là bắt buộc`}"
                >
                  <v-select
                    v-model="dataGeneral.bussinessSectorId"
                    :reduce="item => item.id"
                    label="codeName"
                    :class="{'is-invalid':errors.length > 0 }"
                    :options="dataCombobox || []"
                    placeholder="Lựa chọn Tên ngành nghề"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <h3 class="d-flex align-items-center">
            <feather-icon
              size="20"
              icon="ListIcon"
              class="mr-75"
            />
            <span>
              Số vụ TNLĐ
            </span>
          </h3>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Tổng số</label>
                <b-form-input
                  v-model="dataGeneral.totalCase"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Số vụ có người chết</label>
                <b-form-input
                  v-model="dataGeneral.totalCaseDeath"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Số vụ có từ 2 người bị nạn trở lên</label>
                <b-form-input
                  v-model="dataGeneral.totalCase2Injury"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h3 class="d-flex align-items-center">
            <feather-icon
              size="20"
              icon="UserXIcon"
              class="mr-75"
            />
            <span>
              Số người bị nạn TNLĐ
            </span>
          </h3>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Tổng số</label>
                <b-form-input
                  v-model="dataGeneral.totalWorker"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Số lao động nữ</label>
                <b-form-input
                  v-model="dataGeneral.totalFemaleWorker"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Số người chết</label>
                <b-form-input
                  v-model="dataGeneral.countDeath"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Số người bị thương nặng</label>
                <b-form-input
                  v-model="dataGeneral.countSerious"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h3 class="d-flex align-items-center">
            <feather-icon
              size="20"
              icon="SettingsIcon"
              class="mr-75"
            />
            <span>
              Thiệt hại do TNLĐ
            </span>
          </h3>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Tổng số ngày nghỉ vì tai nạn lao động</label>
                <b-form-input
                  v-model="dataGeneral.absent"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Tổng số</label>
                <b-form-input
                  v-model="dataGeneral.totalCost"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Y tế</label>
                <b-form-input
                  v-model="dataGeneral.healthCost"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Trả lương trong thời gian điều trị</label>
                <b-form-input
                  v-model="dataGeneral.salaryCost"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Bồi thường/trợ cấp</label>
                <b-form-input
                  v-model="dataGeneral.indemnifyCost"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Thiệt hại tài sản (1000đ)</label>
                <b-form-input
                  v-model="dataGeneral.assetCost"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import VSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    VSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dataGeneral: {
        bussinessSectorId: '',
        totalCase: 0,
        totalCaseDeath: 0,
        totalCase2Injury: 0,
        totalWorker: 0,
        totalFemaleWorker: 0,
        countDeath: 0,
        countSerious: 0,
        absent: 0,
        totalCost: 0,
        healthCost: 0,
        salaryCost: 0,
        indemnifyCost: 0,
        assetCost: 0,
      },
      dataCombobox: [],
    }
  },

  methods: {
    async fetchDataCombobox() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_TYPEOFPROFESSION)
      this.dataCombobox = data?.data
      this.dataCombobox.forEach(ele => {
        if (ele.level1) {
          ele.codeName = `${ele.level1} - ${ele.name}`
        }
        if (!ele.level1) {
          ele.codeName = `${ele.level2} - ${ele.name}`
        }
        if (!ele.level1 && !ele.level2) {
          ele.codeName = `${ele.level3} - ${ele.name}`
        }
      })
    },

    accept(bvModalEvt) {
      const model = {
        bussinessSectorId: this.dataGeneral.bussinessSectorId,
        totalCase: Number(this.dataGeneral.totalCase),
        totalCaseDeath: Number(this.dataGeneral.totalCaseDeath),
        totalCase2Injury: Number(this.dataGeneral.totalCase2Injury),
        totalWorker: Number(this.dataGeneral.totalWorker),
        totalWorkerNotOwner: Number(this.dataGeneral.totalWorkerNotOwner),
        totalFemaleWorker: Number(this.dataGeneral.totalFemaleWorker),
        totalFemaleWorkerNotOwner: Number(this.dataGeneral.totalFemaleWorkerNotOwner),
        countDeath: Number(this.dataGeneral.countDeath),
        countDeathNotOwner: Number(this.dataGeneral.countDeathNotOwner),
        countSerious: Number(this.dataGeneral.countSerious),
        countSeriousNotOwner: Number(this.dataGeneral.countSeriousNotOwner),
        absent: Number(this.dataGeneral.absent),
        totalCost: Number(this.dataGeneral.totalCost),
        healthCost: Number(this.dataGeneral.healthCost),
        salaryCost: Number(this.dataGeneral.salaryCost),
        indemnifyCost: Number(this.dataGeneral.indemnifyCost),
        assetCost: Number(this.dataGeneral.assetCost),
      }

      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', model)
        }
      })
    },
    resetData() {
      this.dataGeneral = {
        bussinessSectorId: '',
        totalCase: 0,
        totalCaseDeath: 0,
        totalCase2Injury: 0,
        totalWorker: 0,
        totalWorkerNotOwner: 0,
        totalFemaleWorker: 0,
        totalFemaleWorkerNotOwner: 0,
        countDeath: 0,
        countDeathNotOwner: 0,
        countSerious: 0,
        countSeriousNotOwner: 0,
        absent: 0,
        totalCost: 0,
        healthCost: 0,
        salaryCost: 0,
        indemnifyCost: 0,
        assetCost: 0,
      }
    },

  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
